
import { defineComponent } from 'vue';
import LandingComponent from "@/components/LandingComponent.vue";
import AboutComponent from "@/components/AboutComponent.vue";
import ServicesComponent from "@/components/ServicesComponent.vue";
import WorkComponent from "@/components/WorkComponent.vue";
import ReferencesComponent from "@/components/ReferencesComponent.vue";
import ContactComponent from "@/components/ContactComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
// import HelloWorld from '@/components/HelloWorld.vue'; // @ is an alias to /src

export default defineComponent({
  name: 'HomeView',
  components: {
    FooterComponent,
    ContactComponent,
    ReferencesComponent,
    WorkComponent,
    ServicesComponent,
    AboutComponent,
    LandingComponent
  },
});
