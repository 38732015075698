

import {defineComponent} from "vue";

export default defineComponent({
  // setup(){
  //   return { pageTitle, metaData };
  // },
  name: 'App',
  components: {
    // Navigation,
    // Footer
  }
});

