import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-949390c2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LandingComponent = _resolveComponent("LandingComponent")!
  const _component_AboutComponent = _resolveComponent("AboutComponent")!
  const _component_ServicesComponent = _resolveComponent("ServicesComponent")!
  const _component_WorkComponent = _resolveComponent("WorkComponent")!
  const _component_ReferencesComponent = _resolveComponent("ReferencesComponent")!
  const _component_ContactComponent = _resolveComponent("ContactComponent")!
  const _component_FooterComponent = _resolveComponent("FooterComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_LandingComponent),
    _createVNode(_component_AboutComponent),
    _createVNode(_component_ServicesComponent),
    _createVNode(_component_WorkComponent),
    _createVNode(_component_ReferencesComponent),
    _createVNode(_component_ContactComponent),
    _createVNode(_component_FooterComponent)
  ]))
}